import React from 'react'
const TopSection = React.lazy(() => import("../sections/TopSection"));
const AboutSection = React.lazy(() => import("../sections/AboutSection"));
const ToolsSection = React.lazy(() => import("../sections/ToolsSection"));
const ExperienceSection = React.lazy(() =>
    import("../sections/ExperienceSection")
);
const ProjectsSection = React.lazy(() => import("../sections/ProjectsSection"));
const ContactSection = React.lazy(() => import("../sections/ContactSection"));

const Footer = React.lazy(() => import("../components/Footer"));


const HomePage = () => {
  return (
    <div className="bodyContainer">
                <TopSection />
                <AboutSection />
                {/* <ToolsSection /> */}
                <ExperienceSection />
                <ProjectsSection />
                 {/*<ContactSection /> */}  

                <Footer />
            </div>
  )
}

export default HomePage