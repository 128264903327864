import React, { useState } from "react";
import Loading from "../components/Loading";

const Spline = React.lazy(() => import('@splinetool/react-spline'));

function RoomPage() {
  const [isLoaded, setIsLoaded] = useState(false);


  return (
    <React.Suspense fallback={<Loading />}>
      <Spline
        scene="https://prod.spline.design/u-b8Qac-Vs9fuMhO/scene.splinecode"
        onLoad={() => setIsLoaded(true)}
      />
      {!isLoaded && <Loading />}
    </React.Suspense>
  );
}

export default RoomPage;
