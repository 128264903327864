const imagePaths = [
    require('../assets/threeD/ue1.png'),
    require('../assets/threeD/ue2.png'),
    require('../assets/threeD/ue3.png'),
    require('../assets/threeD/ue4.png'),
    require('../assets/threeD/ue5.png'),
    require('../assets/threeD/ue6.png'),
    require('../assets/threeD/ue7.png'),
    require('../assets/threeD/ue8.png'),
  ];
  
  export default imagePaths;
  