import "./App.scss";
import "./Styles/topText.scss";
import "./Styles/contact.scss";
import "./Styles/projects.scss";
import "./Styles/aboutme.scss";
import "./Styles/icons.scss";
import "./Styles/loading.scss";
import "./Styles/Navigation.scss";
import "./Styles/resume.scss";
import "./Styles/threeDProjects.scss";

import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "react-vertical-timeline-component/style.min.css";

import Loading from "./components/Loading";
import Navigation from "./components/Navigation";
import { Toaster } from "react-hot-toast";
import HomePage from "../src/pages/HomePage";
import ResumePage from "../src/pages/ResumePage";
import ThreeDProjectsPage from "../src/pages/ThreeDProjectsPage";
import ThreeDProjectPage from "../src/pages/ThreeDProjectPage";

import GamePage from "../src/pages/GamePage";
import RoomPage from "../src/pages/RoomPage";

function App() {
    return (
        <BrowserRouter>
            <Suspense fallback={<Loading />}>
                <Navigation />
                <Toaster
                    position="bottom-center"
                    toastOptions={{
                        duration: 5000,
                    }}
                />
                <Routes>
                    <Route exact path="/" element={<HomePage />} />
                    <Route exact path="/3d" element={<ThreeDProjectsPage />} />
                    <Route
                        exact
                        path="/3d/:name"
                        element={<ThreeDProjectPage />}
                    />
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default App;
