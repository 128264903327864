import React from 'react'
import { useTranslation } from "react-i18next";


export default function Helpbox() {
  const { t } = useTranslation();

  return (
    <div className="helpBox">
      <p>W, A, S, D {t("3dtoMoveAround")}</p>
      <p>{t("3dtoLookAround")}</p>
    </div>
  )
}
