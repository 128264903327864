import React, { useState } from "react";
import Loading from "../components/Loading";
import SideMenu from "../components/Sidemenu";
import Helpbox from "../components/Helpbox";
import { useTranslation } from "react-i18next";


const Spline = React.lazy(() => import("@splinetool/react-spline"));

function ResumePage() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedScene, setSelectedScene] = useState("highQuality");
    const { i18n } = useTranslation();
    const language = i18n?.language || "sv"
    const linkToDisplay = language === "sv" ?
        "https://prod.spline.design/MLXkDq0pSP8r35gJ/scene.splinecode"
        : "https://prod.spline.design/pI0r91v-4bPMm9vg/scene.splinecode"


    return (
        <>
            <React.Suspense fallback={<Loading />}>
                <Spline
                    scene={linkToDisplay}
                    onLoad={() => setIsLoaded(true)}
                />
                <Helpbox />
                {/*   {selectedScene === "highQuality" && (
                <>
                    <Spline
                        scene="https://prod.spline.design/6uC1VamQuPZJ7k62/scene.splinecode"
                        onLoad={() => setIsLoaded(true)}
                    />
                    <Helpbox />
                </>
            )}

            {selectedScene === "performance" && (
                <Spline
                    scene="https://prod.spline.design/TzRjXE97yb4cyqtt/scene.splinecode"
                    onLoad={() => setIsLoaded(true)}
                />
            )} */}

                {!isLoaded && <Loading />}
                <SideMenu
                    selectedScene={selectedScene}
                    setSelectedScene={setSelectedScene}
                    setIsLoaded={setIsLoaded}
                />
            </React.Suspense>
        </>
    );
}

export default ResumePage;
