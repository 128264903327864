import React from "react";
import ReactDOM from 'react-dom/client';
import "./index.css";
import App from "./App";
import "./index.css";
import "./i18n";

ReactDOM.createRoot(document.getElementById('root')).render(
    <App />
)
