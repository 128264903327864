import React from "react";
import { useTranslation } from "react-i18next";
import imagePaths from "../components/imagePaths";

export default function GamePage() {
    const { t } = useTranslation();

    return (
        <div className="gameContainer">
            <h1> {t("myUnrealGame")} </h1>
            <div className="gameImageGrid">
                {imagePaths.map((imagePath, index) => (
                    <img
                        key={index}
                        src={imagePath}
                        alt={`Unreal Engine ${index + 1}`}
                        className={`grid-item item${index + 1}`}

                    />
                ))}
            </div>
        </div>
    );
}
