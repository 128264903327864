import React, { useState, useEffect, Suspense } from "react";
import { useParams } from "react-router-dom";
import Loading from "../components/Loading";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ResumePage from "./ResumePage";
import RoomPage from "./RoomPage";
import GamePage from "./GamePage";

export default function ThreeDProjectPage() {
    const [pageName, setPageName] = useState(null);
    const params = useParams();

    useEffect(() => {
        setPageName(params.name);
    }, [params.name, pageName]);

    return (
        <>
                {pageName === "resume" && <div className="resumeContainer fadeIn"> <ResumePage /> </div>}
                {pageName === "room" && <div className="resumeContainer fadeIn"> <RoomPage /> </div>}
                {pageName === "game" && <GamePage />}

            <Link to="/3d" className="non-selectable">
                <div className="projectBackButton">
                    <ArrowBackIosIcon /> Back
                </div>
            </Link>
        </>
    );
}
