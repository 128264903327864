import React from 'react'
import { FadeLoader } from 'react-spinners'

const Loading = () => {
  return (
      <div className="loadingContainer">
    <div className="loadingWrapper">
        <FadeLoader color={"#08f7fe"} size={120} margin={4} /> 
    </div>
    </div>
  )
}

export default Loading