import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import sv from "./translations/sv.json";
import en from "./translations/en.json";

i18next.use(initReactI18next).init({
	resources: {
		sv: {
			translation: sv,
		},
		en: {
			translation: en,
		},
	},
	lng: localStorage.getItem("lng")  || "sv",
});

export default i18next;