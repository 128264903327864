import React, { useState } from "react";
import { IconButton } from "@mui/material";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { useTranslation } from "react-i18next";
import preview from "../assets/threeD/3dpreview.png"
import svResume from "../assets/Leo_Eriksson_CV.pdf"
import enResume from "../assets/Leo_Eriksson_Resume.pdf"
import toast from "react-hot-toast";


const SideMenu = ({ selectedScene, setSelectedScene, setIsLoaded }) => {
    const [expanded, setExpanded] = useState(false);
    const { t, i18n } = useTranslation();
    const handleDownload = () => {
        const language = i18n?.language || "sv"
        const link = document.createElement('a');
        link.href = language === "sv" ? svResume : enResume;
        link.download = language === "sv" ? 'Leo_Eriksson_CV.pdf' : "Leo_Eriksson_Resume.pdf";
        link.click();
    };

    const toggleMenu = () => {
        setExpanded(!expanded);
    };

    const handleSetSelectedScene = (e) => {
        if (e.target.name === selectedScene) {
            toast.error(t("sceneAlreadySelected"));
        }
        else {
            setSelectedScene(e.target.name)
            setIsLoaded(false)
            setExpanded(false)
        }

    }
    return (
        <div className={`sidebar-container ${!selectedScene ? "noSelected" : ""}`}>
            {selectedScene &&
                <IconButton onClick={toggleMenu} className="toggle-button">
                    <DoubleArrowIcon
                        className={`settings-icon ${expanded ? "expanded" : ""}`}
                    />
                </IconButton>
            }
            <div className={`sidebar ${expanded ? "expanded" : ""}`}>
                <h2>{t("information")} </h2>
                {/* <p> {t("sideBarInformationDesc")}</p> */}
                {/* <p> {t("sideBarInformationDesc")}</p> */}

                {/* <h3>{t("chooseScene")}</h3> */}

                <div className="sideBarSelectWrapper">
                    <div className="sideBarQualityCard">
                        {/* <h4> {t("highQuality")}</h4> */}
                        <img src={preview} width="100%" alt="3D-scene with resume on wall" />
                        <p> {t("highQualityDesc")}</p>
                        {/* <button name="highQuality" onClick={((e) => handleSetSelectedScene(e))} className={selectedScene === 'highQuality' ? 'selectedScene' : ''}> {t("select")} </button> */}
                    </div>
                    {/*    <div className="sideBarQualityCard">
                        <h4> {t("performance")} </h4>
                        <img src={doubleresume} width="100%" alt="resume on wall" />
                        <p> {t("performanceDesc")}</p>
                        <button name="performance" onClick={((e) => handleSetSelectedScene(e))} className={selectedScene === 'performance' ? 'selectedScene' : ''}> {t("select")} </button>
                    </div> */}


                </div>

                <button className="downloadPDF" onClick={handleDownload}> {t("downloadPDF")} </button>
            </div>
        </div >
    );
};

export default SideMenu;
