import React from "react";
import "animate.css";
import { useTranslation } from "react-i18next";
import linkedIn from "../assets/linkedin.png";
import LanguageIcon from "@mui/icons-material/Language";
import { NavLink } from "react-router-dom";

const Navigation = () => {
    const { i18n, t } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        localStorage.setItem("lng", language);
    };
    return (
        <div className="customNav">
            <a
                href="https://www.linkedin.com/in/leo-eriksson-a20493222/"
                rel="noreferrer"
                target="_blank"
            >
                <img
                    src={linkedIn}
                    className="d-inline-block align-top"
                    alt="Linkedin logo"
                />
            </a>
            <div className="routerWrapper mobileOnly">
                <NavLink className="navLink" to="/">
                    {t("home")}
                </NavLink>
                {/* <NavLink className="navLink" to="/resume">
                            {t("resume")}
                        </NavLink>
                        <NavLink className="navLink" to="/room">
                            {t("room")}
                        </NavLink> */}
                <NavLink className="navLink" to="/3d">
                    {t("3dProjects")}
                </NavLink>
            </div>
            <div className="switchWrapper">
                <LanguageIcon />
                <span
                    className={`language ${
                        i18n?.language === "sv" ? "activeLang" : ""
                    }`}
                    onClick={() => changeLanguage("sv")}
                >
                    Svenska
                </span>
                <span> | </span>
                <span
                    className={`language ${
                        i18n?.language === "en" ? "activeLang" : ""
                    }`}
                    onClick={() => changeLanguage("en")}
                >
                    English
                </span>
            </div>
        </div>
    );
};

export default Navigation;
